class ZIDXAccountSiteEdit implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxAccountSiteEditContainer";
    }

    getMatchURL() {
        return "/account/sites/edit";
    }

    render() {
        // console.log("site edit");
        const $ = ZIDX.$;

        $(".zidxPageActive input[name=idx_site_name]").on("input", function(this:HTMLInputElement){
            let siteName=this.value;
            $(".zidxSiteDomainSpan").html("https://"+siteName+".peakidxsites.com");
        }).each(function(this:HTMLInputElement){
            let siteName=this.value;
            $(".zidxSiteDomainSpan").html("https://"+siteName+".peakidxsites.com");
        });
        $(".zidxPageActive select[name='listing_index_id']").on("change", function(this:HTMLSelectElement) {
            let $other=$(".zidxPageActive input[name='idx_site_other_feeds']");
            let $otherLabel=$(".zidxPageActive label[for='idx_site_other_feeds']");
            if(this.options[this.selectedIndex].value!="0") {
                $other.hide();
                $otherLabel.hide();
            }else{
                $other.show();
                $otherLabel.show();
            }
        }).trigger("change");

        const $idx_site_contact_from_email = $('#idx_site_contact_from_email');
        const $idx_site_contact_from_smtp_server = $('#idx_site_contact_from_smtp_server');
        const $idx_site_contact_from_smtp_port = $('#idx_site_contact_from_smtp_port');
        const $idx_site_contact_from_smtp_ssl = $('#idx_site_contact_from_smtp_ssl');
        const $idx_site_contact_from_smtp_tls = $('#idx_site_contact_from_smtp_tls');
        const $idx_site_contact_from_smtp_username = $('#idx_site_contact_from_smtp_username');
        const $idx_site_contact_from_smtp_password = $('#idx_site_contact_from_smtp_password');
        const $idx_site_smtp_contact_from_should_verify = $('[name=idx_site_smtp_contact_from_should_verify]');
        const $idx_site_smtp_contact_from_should_verify_container = $idx_site_smtp_contact_from_should_verify.parent().parent();
        const $smtp_settings_verified_text = $('#smtp_settings_verified_text');

        const idx_site_contact_from_email = $idx_site_contact_from_email.val();
        const idx_site_contact_from_smtp_server = $idx_site_contact_from_smtp_server.val();
        const idx_site_contact_from_smtp_port = $idx_site_contact_from_smtp_port.val();
        const idx_site_contact_from_smtp_ssl = $idx_site_contact_from_smtp_ssl.val();
        const idx_site_contact_from_smtp_tls = $idx_site_contact_from_smtp_tls.val();
        const idx_site_contact_from_smtp_username = $idx_site_contact_from_smtp_username.val();
        const idx_site_contact_from_smtp_password = $idx_site_contact_from_smtp_password.val();
        const idx_site_contact_from_smtp_verified = $('#idx_site_contact_from_smtp_verified').val();

        let shouldChangeVerifyValue = true;
        $idx_site_smtp_contact_from_should_verify.change(function (e: any) {
            if (e.originalEvent) {
                shouldChangeVerifyValue = false;
            }
        })

        function checkShouldVerify() {
            if(($idx_site_contact_from_smtp_server.val()+$idx_site_contact_from_smtp_username.val()+$idx_site_contact_from_smtp_password.val()+$idx_site_contact_from_smtp_port.val()).trim()==""){
                $idx_site_smtp_contact_from_should_verify.val(["0"]);
                $("label[for=idx_site_smtp_contact_from_should_verify0], #idx_site_smtp_contact_from_should_verify0").show();
                return;
            }
            $("label[for=idx_site_smtp_contact_from_should_verify0], #idx_site_smtp_contact_from_should_verify0").show();
            if (
                idx_site_contact_from_email == $idx_site_contact_from_email.val() &&
                idx_site_contact_from_smtp_server == $idx_site_contact_from_smtp_server.val() &&
                idx_site_contact_from_smtp_port == $idx_site_contact_from_smtp_port.val() &&
                idx_site_contact_from_smtp_ssl == $idx_site_contact_from_smtp_ssl.val() &&
                idx_site_contact_from_smtp_tls == $idx_site_contact_from_smtp_tls.val() &&
                idx_site_contact_from_smtp_username == $idx_site_contact_from_smtp_username.val() &&
                idx_site_contact_from_smtp_password == $idx_site_contact_from_smtp_password.val()
            ) {
                $smtp_settings_verified_text.show();
                if (idx_site_contact_from_smtp_verified == "1") {
                    $idx_site_smtp_contact_from_should_verify.val(["0"]);
                } else {
                    if (shouldChangeVerifyValue) {
                        $idx_site_smtp_contact_from_should_verify.val(["1"]);
                        $("label[for=idx_site_smtp_contact_from_should_verify0], #idx_site_smtp_contact_from_should_verify0").hide();
                    }
                }
            } else {
                $smtp_settings_verified_text.hide();
                $idx_site_smtp_contact_from_should_verify_container.show();
                if (shouldChangeVerifyValue) {
                    $("label[for=idx_site_smtp_contact_from_should_verify0], #idx_site_smtp_contact_from_should_verify0").hide();
                    $idx_site_smtp_contact_from_should_verify.val(["1"]);
                }
            }
        }

        $idx_site_contact_from_email.change(checkShouldVerify);
        $idx_site_contact_from_smtp_server.change(checkShouldVerify);
        $idx_site_contact_from_smtp_port.change(checkShouldVerify);
        $idx_site_contact_from_smtp_ssl.change(checkShouldVerify);
        $idx_site_contact_from_smtp_tls.change(checkShouldVerify);
        $idx_site_contact_from_smtp_username.change(checkShouldVerify);
        $idx_site_contact_from_smtp_password.change(checkShouldVerify);

        checkShouldVerify();
    }
}